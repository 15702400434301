import React, { useEffect, useState } from 'react'
import {
  BottomTip,
  BusinessTabsWrap,
  ClientInfo,
  ClientInfoTitle,
  DownloadBox,
  DownloadBoxLeft,
  DownloadBoxRight,
  DownloadBtn,
  DownloadFullBox,
  IOSBtnGroup,
  IOSDownloadBtn,
  MacDownloadWrap,
  MacFloatTip,
  MacIdentify,
  PcBtnGroup,
} from './atom'
import DownloadTabs from '../../../components/download-tabs'
import {
  Platform,
  SoftwareVersionType,
} from '@raylink-overseas/common/constants/platform'
import iconWindows from '@svg/download-windows.svg'
import iconMacOS from '@svg/download-macOS.svg'
import iconAndroid from '@svg/download-android.svg'
import iconIOS from '@svg/download-iOS.svg'
import IosIcon from '@images/download/iOS.svg'
import WinBusinessImg from '@images/download/Windows_image-business.png'
import MacOSBusinessImg from '@images/download/macOS_image-business.png'
import AndroidBusinessImg from '@images/download/android_image-business.png'
import IOSBusinessImg from '@images/download/ios_image-business.png'
import ArrowTop from '@svg/arrow-top.svg'
import { AndroidBtnGroup, AndroidDownloadBtn } from '../../../components/atoms'
import { BusinessLink } from '@raylink-overseas/common/constants/public'
import { QRCodeSVG } from 'qrcode.react'
import { BusinessAPI, Axios } from '@raylink-overseas/common/api'
import { APIResponse } from '@raylink-overseas/common/api/type'

export interface QueryClientResp {
  id: number
  softwareVersion: string // 版本号
  softwareVersionType: number // 平台类型，枚举见 SoftwareVersionType
  updateContent: string // 更新内容描述
  isForce: number // 是否强制更新
  downloadLink: string // 境内下载链接
  downloadLinkOverseas: string // 境外下载链接
  version: number // 乐观锁
  deleted: number // 逻辑删除
  creator: string // 创建人
  createTime: string // 创建时间
  updator: string // 更新人
  updateTime: string // 更新时间
  hash: string // 哈希值
  images: string // 图片信息（JSON格式）{ url: string, title: string, content: string }[]
  isPublished: number // 是否发布
  publishTime: string // 发布时间
  ota: object[] // ota信息
}

export default function BusinessTabs() {
  const [downloadInfos, setDownloadInfos] = useState<QueryClientResp[]>()
  const findSoftware = (softwareType: SoftwareVersionType) => {
    return downloadInfos?.find(
      info => info.softwareVersionType === softwareType
    )
  }

  useEffect(() => {
    const http = new Axios(BusinessAPI.host)
    const urls = [
      SoftwareVersionType.masterWin,
      SoftwareVersionType.clientWin,
      SoftwareVersionType.masterMacApple,
      SoftwareVersionType.masterMacIntel,
      SoftwareVersionType.clientMacApple,
      SoftwareVersionType.clientMacIntel,
      SoftwareVersionType.masterAndroid,
      SoftwareVersionType.masterIOS,
    ].map(
      type => `${BusinessAPI.queryDownloadInfo}?softwareVersionType=${type}`
    )
    const promises = urls.map(url =>
      http.get<QueryClientResp>(url, {
        headers: {
          version: '1.0.0',
          channel: 1,
        },
      })
    )

    Promise.all(promises).then(respList => {
      const downloadInfos = (
        respList.filter(
          resp => resp && resp?.code === 200 && resp?.data
        ) as unknown as APIResponse<QueryClientResp>[]
      ).map(resp => resp.data)
      setDownloadInfos(downloadInfos)
    })
  }, [])

  const tabs = [
    {
      platform: Platform.windows,
      icon: iconWindows,
      name: 'Windows',
      children: (
        <DownloadFullBox>
          <DownloadBox>
            <DownloadBoxLeft>
              <ClientInfoTitle className="client-title">
                Avica Business for Windows
              </ClientInfoTitle>
              <ClientInfo className="client-info">
                <div className="description">
                  Download and remotely control Windows and Mac devices.
                </div>
                <div className="introduction">
                  <div className="intro-item">
                    <div className="dot" />
                    Current version:{' '}
                    {
                      findSoftware(SoftwareVersionType.masterWin)
                        ?.softwareVersion
                    }
                  </div>
                  <div className="intro-item">
                    <div className="dot" />
                    Available for Windows 7 or newer (64-bit)
                  </div>
                </div>
              </ClientInfo>
              <PcBtnGroup className="btn-group">
                <div className="btn-wrap">
                  <DownloadBtn
                    id="download"
                    href={
                      findSoftware(SoftwareVersionType.masterWin)?.downloadLink
                    }
                    rel="nofollow"
                  >
                    Download Viewer
                  </DownloadBtn>
                  <span className="download-client-tip">
                    Install on the local device
                  </span>
                </div>
                <div className="btn-wrap">
                  <DownloadBtn
                    id="download"
                    href={
                      findSoftware(SoftwareVersionType.clientWin)?.downloadLink
                    }
                    rel="nofollow"
                  >
                    Download Host
                  </DownloadBtn>
                  <span className="download-client-tip">
                    Install on the remote device
                  </span>
                </div>
              </PcBtnGroup>
            </DownloadBoxLeft>
            <DownloadBoxRight>
              <img className="img img-pc" src={WinBusinessImg} alt="" />
            </DownloadBoxRight>
          </DownloadBox>
          <BottomTip>
            *By installing and using Avica, you accept our{' '}
            <a
              href="https://www.avica.link/terms-and-conditions.html"
              target="_blank"
              rel="nofollow noreferrer"
            >
              Terms & Conditions
            </a>{' '}
            and our{' '}
            <a
              href="https://www.avica.link/privacy-policy.html"
              target="_blank"
              rel="nofollow noreferrer"
            >
              Privacy Policy
            </a>
            .
          </BottomTip>
        </DownloadFullBox>
      ),
    },
    {
      platform: Platform.mac,
      icon: iconMacOS,
      name: 'macOS',
      children: (
        <DownloadFullBox>
          <DownloadBox>
            <DownloadBoxLeft>
              <ClientInfoTitle className="client-title">
                Avica Business for macOS
              </ClientInfoTitle>
              <ClientInfo className="client-info">
                <div className="description">
                  Simplify the access to Windows and macOS devices from your
                  Mac.
                </div>
                <div className="introduction">
                  <div className="intro-item">
                    <div className="dot" />
                    Current version:{' '}
                    {
                      findSoftware(SoftwareVersionType.masterMacApple)
                        ?.softwareVersion
                    }
                  </div>
                  <div className="intro-item">
                    <div className="dot" />
                    Available for macOS 10.12 or newer
                  </div>
                </div>
              </ClientInfo>
              <PcBtnGroup className="btn-group">
                <div className="btn-wrap">
                  <span className="download-client-label">
                    Download Viewer for:
                  </span>
                  <MacDownloadWrap>
                    <DownloadBtn
                      id="download"
                      className="btn"
                      href={
                        findSoftware(SoftwareVersionType.masterMacApple)
                          ?.downloadLink
                      }
                      rel="nofollow"
                    >
                      <span>Apple Silicon Mac</span>
                      <ArrowTop />
                    </DownloadBtn>
                    <DownloadBtn
                      id="download"
                      className="btn btn-default"
                      href={
                        findSoftware(SoftwareVersionType.masterMacIntel)
                          ?.downloadLink
                      }
                      rel="nofollow"
                    >
                      Intel-Based Mac
                    </DownloadBtn>
                  </MacDownloadWrap>
                  <span className="download-client-tip">
                    Install on the local device
                  </span>
                </div>
                <div className="btn-wrap">
                  <span className="download-client-label">
                    Download Host for:
                  </span>
                  <MacDownloadWrap>
                    <DownloadBtn
                      id="download"
                      className="btn"
                      href={
                        findSoftware(SoftwareVersionType.clientMacApple)
                          ?.downloadLink
                      }
                      rel="nofollow"
                    >
                      <span>Apple Silicon Mac</span>
                      <ArrowTop />
                    </DownloadBtn>
                    <DownloadBtn
                      id="download"
                      className="btn btn-default"
                      href={
                        findSoftware(SoftwareVersionType.clientMacIntel)
                          ?.downloadLink
                      }
                      rel="nofollow"
                    >
                      Intel-Based Mac
                    </DownloadBtn>
                  </MacDownloadWrap>
                  <span className="download-client-tip">
                    Install on the remote device
                  </span>
                </div>
              </PcBtnGroup>
              <MacIdentify className="mac-identify">
                <span>How to identify a Mac&lsquo;s chip type?</span>
                <MacFloatTip className="mac-float-tip">
                  <div className="tip-desc">
                    Download the proper Avica version according to your
                    Mac&lsquo;s chip to enhance your remote experience.
                  </div>
                  <div className="tip-question">
                    How to identify your Mac&lsquo;s chip type?
                  </div>
                  <div className="tip-desc choose">
                    1. Choose Apple menu <img src={IosIcon} alt="" />
                    &gt; About This Mac.
                  </div>
                  <div className="tip-desc">
                    2. Check the Chip or Processor label in the Overview tab to
                    determine if the chip is based on Intel or Apple.
                  </div>
                </MacFloatTip>
              </MacIdentify>
            </DownloadBoxLeft>
            <DownloadBoxRight>
              <img className="img img-pc" src={MacOSBusinessImg} alt="" />
            </DownloadBoxRight>
          </DownloadBox>

          <BottomTip>
            *By installing and using Avica, you accept our{' '}
            <a
              href="https://www.avica.link/terms-and-conditions.html"
              target="_blank"
              rel="nofollow noreferrer"
            >
              Terms & Conditions
            </a>{' '}
            and our{' '}
            <a
              href="https://www.avica.link/privacy-policy.html"
              target="_blank"
              rel="nofollow noreferrer"
            >
              Privacy Policy
            </a>
            .
          </BottomTip>
        </DownloadFullBox>
      ),
    },
    {
      platform: Platform.android,
      icon: iconAndroid,
      name: 'Android',
      children: (
        <DownloadFullBox>
          <DownloadBox>
            <DownloadBoxLeft>
              <ClientInfoTitle className="client-title">
                Avica Business for Android
              </ClientInfoTitle>
              <ClientInfo className="client-info">
                <div className="description">
                  Access Windows and macOS devices from your Android, anywhere,
                  anytime!
                </div>
                <div className="introduction">
                  <div className="intro-item">
                    <div className="dot" />
                    Current version:{' '}
                    {
                      findSoftware(SoftwareVersionType.masterAndroid)
                        ?.softwareVersion
                    }
                  </div>
                  <div className="intro-item">
                    <div className="dot" />
                    Available for Android 8.0 or newer
                  </div>
                </div>
              </ClientInfo>
              <AndroidBtnGroup className="btn-group">
                <AndroidDownloadBtn
                  id="download"
                  className="btn-google-play"
                  href={BusinessLink.BusinessGooglePlayLink}
                  rel="nofollow"
                  target="__blank"
                >
                  <div className="qrcode">
                    <QRCodeSVG
                      value={BusinessLink.BusinessGooglePlayLink}
                      size={130}
                    />
                  </div>
                </AndroidDownloadBtn>
                {/* <AndroidDownloadBtn
                  id="download"
                  className="btn-galaxy-store"
                  href={BusinessLink.BusinessGalaxyStoreLink}
                  rel="nofollow"
                  target="__blank"
                >
                  <div className="qrcode">
                    <QRCodeSVG
                      value={BusinessLink.BusinessGalaxyStoreLink}
                      size={130}
                    />
                  </div>
                </AndroidDownloadBtn>
                <AndroidDownloadBtn
                  id="download"
                  className="btn-app-gallery"
                  href={BusinessLink.BusinessAppGalleryLink}
                  rel="nofollow"
                  target="__blank"
                >
                  <div className="qrcode">
                    <QRCodeSVG
                      value={BusinessLink.BusinessAppGalleryLink}
                      size={130}
                    />
                  </div>
                </AndroidDownloadBtn> */}
                <AndroidDownloadBtn
                  id="download"
                  className="btn-apk-download"
                  href={
                    findSoftware(SoftwareVersionType.masterAndroid)
                      ?.downloadLink
                  }
                  rel="nofollow"
                >
                  <span>Download APK File</span>
                  <div className="qrcode">
                    <QRCodeSVG
                      value={
                        findSoftware(SoftwareVersionType.masterAndroid)
                          ?.downloadLink ?? ''
                      }
                      size={130}
                    />
                  </div>
                </AndroidDownloadBtn>
              </AndroidBtnGroup>
            </DownloadBoxLeft>
            <DownloadBoxRight>
              <img className="img img-mobile" src={AndroidBusinessImg} alt="" />
            </DownloadBoxRight>
          </DownloadBox>
          <BottomTip>
            *By installing and using Avica, you accept our{' '}
            <a
              href="https://www.avica.link/terms-and-conditions.html"
              target="_blank"
              rel="nofollow noreferrer"
            >
              Terms & Conditions
            </a>{' '}
            and our{' '}
            <a
              href="https://www.avica.link/privacy-policy.html"
              target="_blank"
              rel="nofollow noreferrer"
            >
              Privacy Policy
            </a>
            .
          </BottomTip>
        </DownloadFullBox>
      ),
    },
    {
      platform: Platform.ios,
      icon: iconIOS,
      name: 'iOS',
      children: (
        <DownloadFullBox>
          <DownloadBox>
            <DownloadBoxLeft>
              <ClientInfoTitle className="client-title">
                Avica Business for iOS
              </ClientInfoTitle>
              <ClientInfo className="client-info">
                <div className="description">
                  Effortlessly access Windows and macOS devices from your
                  iPhone/iPad.
                </div>
                <div className="introduction">
                  <div className="intro-item">
                    <div className="dot" />
                    Current version:{' '}
                    {
                      findSoftware(SoftwareVersionType.masterIOS)
                        ?.softwareVersion
                    }
                  </div>
                  <div className="intro-item">
                    <div className="dot" />
                    Available for iOS 11.0 or newer
                  </div>
                </div>
              </ClientInfo>
              <IOSBtnGroup className="btn-group">
                <div className="qrcode">
                  <QRCodeSVG
                    value={
                      findSoftware(SoftwareVersionType.masterIOS)
                        ?.downloadLink ?? ''
                    }
                    size={108}
                  />
                </div>
                <IOSDownloadBtn
                  id="download"
                  href={
                    findSoftware(SoftwareVersionType.masterIOS)?.downloadLink
                  }
                  rel="nofollow noreferrer"
                  target="__blank"
                />
              </IOSBtnGroup>
            </DownloadBoxLeft>
            <DownloadBoxRight>
              <img className="img img-mobile" src={IOSBusinessImg} alt="" />
            </DownloadBoxRight>
          </DownloadBox>
          <BottomTip>
            *By installing and using Avica, you accept our{' '}
            <a
              href="https://www.avica.link/terms-and-conditions.html"
              target="_blank"
              rel="nofollow noreferrer"
            >
              Terms & Conditions
            </a>{' '}
            and our{' '}
            <a
              href="https://www.avica.link/privacy-policy.html"
              target="_blank"
              rel="nofollow noreferrer"
            >
              Privacy Policy
            </a>
            .
          </BottomTip>
        </DownloadFullBox>
      ),
    },
  ]

  return (
    <BusinessTabsWrap>
      <DownloadTabs tabs={tabs} />
    </BusinessTabsWrap>
  )
}
