import React from 'react'
import { Link } from 'gatsby'
import { Layout } from '@raylink-overseas/common/components'
import { DownloadPage, DownloadWrapper, Title } from '../atoms'
import DownloadWhy from '../components/download-why'
import BusinessTabs from './components/business-tabs'
import DownloadContact from '../components/download-contact'

export default function DownloadBusiness() {
  return (
    <Layout pageType="download">
      <DownloadPage className="business-page">
        <DownloadWrapper>
          <Title>
            <h1 className="business-title">
              Avica
              <span> Business </span>Downloads
            </h1>
            <div className="sub-title">
              Download Avica remote desktop app for business and improve your
              team&apos;s work efficiency.
            </div>
            <Link className="switch-download-page" to="/downloads/">
              <span>Switch to Avica Personal Downloads</span>
            </Link>
          </Title>
          <BusinessTabs />
        </DownloadWrapper>
        <DownloadWhy isBusiness />
        <DownloadContact isBusiness />
      </DownloadPage>
    </Layout>
  )
}
