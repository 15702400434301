import React, { useEffect, useState } from 'react'
import { defaultPlatform, isFunction } from '@raylink-overseas/common/utils'
import {
  TabContainer,
  Tab,
  TabContentContainer,
  TabContent,
  TabViewBox,
  TabsWrap,
  TabChildren,
} from './atom'
import { Platform } from '@raylink-overseas/common/constants/platform'

export interface TabItem {
  platform: number
  name: string
  icon: ReactComponent
  children: React.ReactNode | ((tab: TabItem) => React.ReactNode)
}

export interface DownloadTabsProps {
  tabs?: TabItem[]
}

export default function DownloadTabs(props: DownloadTabsProps) {
  const { tabs = [] } = props
  const [activeTab, setActiveTab] = useState<number>(Platform.windows)

  useEffect(() => {
    // 设置默认选中端
    setActiveTab(defaultPlatform().platform ?? Platform.windows)
  }, [])

  return (
    <TabsWrap>
      <TabContainer>
        {tabs.map(tab => (
          <Tab
            key={tab.platform}
            className={tab.platform === activeTab ? `tab-active` : ''}
            onClick={() => setActiveTab(tab.platform)}
          >
            <tab.icon />
            <span className="name">{tab.name}</span>
          </Tab>
        ))}
      </TabContainer>

      <TabViewBox>
        <TabContentContainer
          active={tabs.findIndex(({ platform }) => platform === activeTab)} // 传入激活选项的索引值
        >
          {tabs.map(tab => (
            <TabContent key={tab.platform}>
              {/* 移动端下隐藏不是激活的选项，以自适应高度 */}
              <TabChildren
                className={activeTab !== tab.platform ? 'display-none' : ''}
              >
                {isFunction(tab.children) ? tab.children(tab) : tab.children}
              </TabChildren>
            </TabContent>
          ))}
        </TabContentContainer>
      </TabViewBox>
    </TabsWrap>
  )
}
