import styled from 'styled-components'
import { color, Media } from '@raylink-overseas/common/theme'

export const TabsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 48px;
  ${Media.laptop`
    gap: 38px;
  `}
  ${Media.ipad`
    gap: 28px;
  `}
  ${Media.phone`
    flex-wrap: wrap;
    gap: 0.28rem;
    padding: 0 0.32rem;
    width: 100%;
  `}
`

export const Tab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  flex-shrink: 0;
  width: 152px;
  height: 148px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 10px 30px 0px rgba(204, 204, 204, 0.2);
  cursor: pointer;
  .name {
    color: ${color.textDark};
    font-size: 18px;
    font-weight: 500;
  }
  &:active,
  &.tab-active {
    .name {
      color: #fff;
    }
    svg {
      path {
        fill: #fff;
      }
    }
  }
  &:active {
    background: linear-gradient(90deg, #197ef3 0%, #445fff 100%);
  }
  &.tab-active {
    background: linear-gradient(290deg, #445fff 53.77%, #55bcee 97.08%);
    box-shadow: 0px 20px 30px 0px rgba(204, 204, 204, 0.3);
  }
  ${Media.phone`
    justify-content: flex-start;
    flex-direction: row;
    gap: 0.12rem;
    flex: 1;
    padding: 0.24rem 0.48rem;
    width: unset;
    height: unset;
    border-radius: 0.12rem;
    gap: 0.08rem;
    background: #f5f7ff;
    box-shadow: none;
    .name {
      color: ${color.textDark};
      font-size: 0.28rem;
    }
    svg {
      width: 0.72rem;
      height: 0.72rem;
    }
  `}
`

export const TabViewBox = styled.div`
  --tab-padding: 24px; // 左右两边留空显示阴影
  --tab-view-width: 1200px;
  --tab-width: calc(var(--tab-view-width) + var(--tab-padding) * 2);
  overflow-x: hidden;
  width: var(--tab-width);
  ${Media.laptop`
    --tab-view-width: 1108px;
  `}
  ${Media.ipad`
    --tab-view-width: 896px;
  `}
  ${Media.phone`
    --tab-padding: 0.32rem;
    --tab-width: 100vw;
  `}
`

export const TabContentContainer = styled.div<{ active: number }>`
  position: relative;
  z-index: 99;
  display: flex;
  transform: translateX(calc(var(--tab-width) * ${({ active }) => -active}));
  transition: transform 0.3s ease-in-out 0s;
  ${Media.phone`
    transition: none;
  `}
`

export const TabContent = styled.div`
  padding: 0 var(--tab-padding);
  width: var(--tab-width);
  height: fit-content;
  flex-shrink: 0;
  &.display-none {
    display: none;
  }
`

export const TabChildren = styled.div`
  width: 100%;
  ${Media.phone`
    &.display-none {
      display: none;
    }
  `}
`
