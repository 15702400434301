import styled from 'styled-components'
import { color, Media } from '@raylink-overseas/common/theme'
import MacDownloadBtnBg from '@images/download/mac-download-btn.png'

export const BusinessTabsWrap = styled.div``

export const DownloadBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  ${Media.phone`
    justify-content: flex-start;
    flex-direction: column;
    gap: 0.6rem;
  `}
`

export const DownloadFullBox = styled.div`
  position: relative;
  padding: 80px 0 0;
  height: 672px;
  ${Media.phone`
    width: 100%;
    height: unset;
    padding: 0.8rem 0 2.28rem;
  `}
`

export const DownloadBoxLeft = styled.div`
  .client-info {
    margin: 16px 0 32px;
  }
  .btn-group {
    margin-bottom: 16px;
  }
  ${Media.phone`
    display: flex;
    flex-direction: column;
    align-items: center;
    .client-title {
      order: 0;
    }
    .client-info {
      order: 3;
      margin: 0;
      margin-top: 0.56rem;
    }
    .btn-group {
      order: 1;
      margin: 0;
      margin-top: 0.56rem;
    }
    .mac-identify {
      order: 2;
      margin-top: 0.32rem;
    }
  `}
`

export const DownloadBoxRight = styled.div`
  .img-pc {
    width: 620px;
    height: 384px;
  }
  .img-mobile {
    width: 475px;
    height: 413px;
  }
  ${Media.ipad`
    .img-pc {
      width: 416px;
      height: auto;
    }
    .img-mobile {
      width: 416px;
      height: auto;
    }
  `}
  ${Media.phone`
    .img {
      width: 100%;
      height: unset;
    }
  `}
`

export const ClientInfoTitle = styled.div`
  color: ${color.textBlack};
  font-size: 36px;
  font-weight: 800;
  line-height: 1.1;
  ${Media.ipad`
    font-size: 30px;
  `}
  ${Media.phone`
    font-size: 0.48rem;
    text-align: center
  `}
`

export const ClientInfo = styled.div`
  width: 480px;
  flex-shrink: 0;
  .description {
    color: ${color.textDark};
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
  }
  .introduction {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .intro-item {
      display: flex;
      align-items: center;
      line-height: 28px;
      color: ${color.textDark};
      font-size: 18px;
      font-weight: 400;
      gap: 16px;
      .dot {
        height: 28px;
        align-self: flex-start;
        display: flex;
        align-items: center;
        &::before {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: ${color.primary};
        }
      }
    }
  }
  ${Media.ipad`
    width: 456px;
    .description {
      font-size: 16px;
    }
    .introduction {
      .intro-item {
        font-size: 16px;
      }
    }
  `}
  ${Media.phone`
    width: 100%;
    .description {
      padding: 0 0.2rem;
      font-size: 0.32rem;
    }
    .introduction {
      margin-top: 0.2rem;
      padding: 0 0.2rem;
      gap: 0.16rem;
      .intro-item {
        line-height: 0.48rem;
        font-size: 0.32rem;
        gap: 0.16rem;
        .dot {
          height: 0.48rem;
          &::before {
            width: 0.1rem;
            height: 0.1rem;
          }
        }
      }
    }
  `}
`

export const PcBtnGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  .btn-wrap {
    display: flex;
    flex-direction: column;
    .download-client-label {
      margin-bottom: 12px;
      color: #1f2329;
      font-size: 16px;
      font-weight: 500;
    }
    .download-client-tip {
      margin-top: 8px;
      color: #848484;
      text-align: left;
      font-size: 14px;
      line-height: 1.5;
    }
  }
  ${Media.phone`
    flex-direction: column;
    gap: 0.24rem;
    .btn-wrap {
      .download-client-label {
        margin-bottom: 0.2rem;
        font-size: 0.32rem;
        text-align: center;
      }
      .download-client-tip {
        margin-top: 0.08rem;
        font-size: 0.28rem;
        text-align: center;
      }
    }
  `}
`

export const IOSBtnGroup = styled.div`
  display: flex;
  gap: 16px;
  .qrcode {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    width: 120px;
    height: 120px;
    border-radius: 8px;
    border: 1px solid #e6e6e6;
  }
  ${Media.phone`
    .qrcode {
      padding: 0.1rem;
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 0.16rem;
      & > svg {
        width: 100%;
        height: 100%;
      }
    }
  `}
`

export const IOSDownloadBtn = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 174px;
  height: 58px;
  background: url(${MacDownloadBtnBg}) center / 100% 100% no-repeat;
  ${Media.phone`
    width: 3.42rem;
    height: 1.12rem;
  `}
`

export const DownloadBtn = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  height: 44px;
  border-radius: 4px;
  border: none;
  background: linear-gradient(290deg, #445fff 53.77%, #55bcee 97.08%);
  box-shadow: 2px 4px 10px 0px rgba(255, 255, 255, 0.2) inset;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  &:hover {
    color: #fff;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.1) 100%
      ),
      linear-gradient(90deg, #197ef3 0%, #445fff 100%);
  }
  &:active {
    color: #fff;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.05) 0%,
        rgba(0, 0, 0, 0.05) 100%
      ),
      linear-gradient(90deg, #197ef3 0%, #445fff 100%);
  }
  &.btn-default {
    background: #fff;
    color: #1f2329;
    box-shadow: 0px 2px 16px 2px rgba(204, 204, 204, 0.24);
    &:hover {
      color: ${color.primary};
    }
  }
  ${Media.phone`
    height: 0.88rem;
    padding: 0 0.48rem;
    flex-shrink: 0;
    font-size: 0.32rem;
    border-radius: 0.08rem;
  `}
`

export const MacDownloadWrap = styled.div`
  position: relative;
  z-index: 9;
  .btn {
    gap: 10px;
    svg {
      width: 12px;
      height: 8px;
      transform: rotate(180deg);
      transition: all 0s ease 0.2s;
    }
  }
  .btn-default {
    width: 100%;
    position: absolute;
    bottom: 9999px;
    transform: translateY(calc(100% + 2px));
    transition: all 0s ease 0.2s;
  }
  &:hover {
    .btn svg {
      transform: rotate(0);
    }
    .btn-default {
      bottom: 0;
    }
  }
  ${Media.phone`
    .btn {
      gap: 0.2rem;
      svg {
        width: 0.24rem;
        height: 0.16rem;
      }
    }
  `}
`

export const MacIdentify = styled.div`
  position: relative;
  width: fit-content;
  border-bottom: 1px solid #848484;
  font-size: 16px;
  line-height: 1.5;
  color: #848484;
  .mac-float-tip {
    display: none;
  }
  &:hover {
    border: none;
    .mac-float-tip {
      position: absolute;
      left: 0;
      bottom: -10px;
      transform: translateY(100%);
      z-index: 9999;
      display: block;
    }
  }
  ${Media.phone`
    font-size: 0.32rem;
    &:hover {
      .mac-float-tip {
        left: 50%;
        bottom: -0.2rem;
        transform: translate(-50%, 100%);
      }
    }
  `}
`

export const MacFloatTip = styled.div`
  width: 356px;
  padding: 16px 20px;
  box-shadow: 0px 0px 20px 0px #cccccc;
  border-radius: 8px;
  background: #fff;
  font-size: 14px;
  line-height: 1.25;
  .tip-desc {
    color: #595959;
  }
  .tip-question {
    font-weight: 500;
    color: #1f2329;
    margin: 12px 0 4px;
  }
  .choose {
    margin-bottom: 4px;
  }
  ::after {
    content: '';
    position: absolute;
    background: #fff;
    width: 16px;
    height: 16px;
    top: -14px;
    left: 24px;
    transform: translate(-50%, 50%) rotate(45deg);
  }
  ${Media.phone`
    width: 6.96rem;
    padding: 0.32rem;
    box-shadow: 0px 0px 0.4rem 0px #cccccc;
    border-radius: 0.16rem;
    font-size: 0.28rem;
    .tip-question {
      margin: 0.24rem 0 0.08rem;
    }
    .choose {
      margin-bottom: 0.08rem;
    }
    ::after {
      width: 0.32rem;
      height: 0.32rem;
      top: -0.28rem;
      left: 50%;
      transform: translate(-50%, 50%) rotate(45deg);
    }
  `}
`

export const BottomTip = styled.div`
  position: absolute;
  left: 50%;
  bottom: 112px;
  transform: translateX(-50%);
  color: ${color.textDark};
  font-size: 18px;
  white-space: nowrap;
  text-align: center;
  ${Media.phone`
    bottom: 0.8rem;
    width: 100%;
    font-size: 0.28rem;
    white-space: wrap;
  `}
`
